export default defineNuxtRouteMiddleware((to, from) => {
  // Share
  const shareBlob = useState<Blob>('shareBlob')

  // If share blob
  if (shareBlob.value) {
    // Proceed
    return

  } else {
    // Navigate to index
    return navigateTo('/')

  }
  
})